import gql from 'graphql-tag';

export const UPDATE_ORDER = gql`
    mutation UpdateOrder($id: ID!, $hasPaid: Boolean) {
        updateOrder(id: $id, data: { hasPaid: $hasPaid }) {
            id
            hasPaid
        }
    }
`;
