import gql from 'graphql-tag';

export const ALL_ORDERS = gql`
    query orders {
        orders {
            id
            email
            name
            phone
            comment
            date
            totalItems
            checkoutTotal
            hasPaid
            dish0
            qty0
            dish1
            qty1
            dish2
            qty2
            dish3
            qty3
            dish4
            qty4
            dish5
            qty5
            dish6
            qty6
            dish7
            qty7
            dish8
            qty8
            dish9
            qty9
            dish10
            qty10
        }
    }
`;
