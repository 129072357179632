import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { UPDATE_ORDER } from '../graphql/mutations/updateOrder';

// TODO: Edit the hasPaid field

// local imports
import { ALL_ORDERS } from '../graphql/queries/allOrders';

export default function AllWishes({ user, menuState, setMenuState }) {
    const [id, setId] = useState('');
    const [hasPaid, setHasPaid] = useState();
    const [liveOrderDate] = useState();

    useEffect(() => {
        window.localStorage.setItem('liveOrderDate', liveOrderDate);
    }, [liveOrderDate]);

    // MAIN QUERY
    const { loading, data, error, refetch } = useQuery(ALL_ORDERS, {});

    //  EDIT MUTATION
    const [updateOrder] = useMutation(UPDATE_ORDER, {
        variables: { id, hasPaid },
        refetchQueries: ['allOrders'],
    });

    useEffect(() => {
        setId(id);
        setHasPaid(hasPaid);
    }, [hasPaid, id]);

    const handleEdit = (id, hasPaid) => {
        console.log(id, hasPaid, !hasPaid);
        setId(id);
        setHasPaid(!hasPaid);
        setTimeout(() => {
            updateOrder().then(() => {
                refetch();
            });
        }, 200);
    };

    if (loading) return <h2>Loading ....</h2>;
    if (error) return `Error! ${error.message}`;

    return (
        <Wrapper>
            <Intro>Click on any item to update Paid status.</Intro>
            {data.orders
                .filter((result) => result)
                .map((item) => (
                    <Dish onClick={() => handleEdit(item.id, item.hasPaid)} key={item.id}>
                        <Date>{moment(item.date).format('llll')}</Date>
                        <CheckoutTotal>${parseFloat(item.checkoutTotal).toFixed(2)}</CheckoutTotal>
                        <Total>{item.totalItems}</Total>
                        <Contact>
                            <span>{item.name}</span>
                            <span>{item.email}</span>
                            <span>{item.phone}</span>
                            <p>
                                <strong>Comment: </strong> {item.comment}
                            </p>
                        </Contact>

                        <Orders>
                            <DishItem className="dish">
                                <Quantity>{item.qty}</Quantity>
                                <DishOrder>{item.dish}</DishOrder>
                            </DishItem>
                        </Orders>
                        <Paid>Paid?: {item.hasPaid === true ? <Yes>yes</Yes> : <No>no</No>}</Paid>
                    </Dish>
                ))}
        </Wrapper>
    );
}

AllWishes.propTypes = {
    user: PropTypes.string,
    menuState: PropTypes.string,
    setMenuState: PropTypes.any,
};

// define your styles
const Wrapper = styled.div`
    background-color: var(--salmon);
    max-width: 900px;
    overflow: auto;
    padding: 5px 15px 15px 15px;
    position: relative;
`;

const Intro = styled.p`
    color: black;
    font-size: 14px;
    font-style: italic;
    padding: 10px;
`;

const Dish = styled.div`
    border-top: 1px dotted gray;
    display: block;
    margin: 15px 0 30px 0;
    padding: 10px;
    background: rgba(255, 255, 255, 0.7);
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    position: relative;
    &:first-of-type {
        border-top: none;
    }
`;

const Contact = styled.div`
    margin-top: 40px;
    font-size: 13px;
    span {
        display: inline-block;
        padding: 0 3px 0 0;

        &:first-of-type {
            display: block;
            font-weight: 600;
            font-size: 15px;
            padding-left: 0;
            &:before {
                content: '';
            }
        }

        &:last-of-type {
            &:before {
                content: '•';
                padding-right: 5px;
            }
        }
    }
    p {
        padding-top: 5px;
        strong {
            color: var(--salmon);
        }
    }
`;

const Date = styled.p`
    color: white;
    font-weight: 700;
    background-color: var(--salmon);
    border-bottom-right-radius: 10px;
    display: inline-block;
    position: absolute;
    padding: 10px 20px 10px 10px;
    top: -1px;
    left: 0;
`;

const CheckoutTotal = styled.p`
    font-weight: 700;
    position: absolute;
    right: 46px;
    text-align: right;
    top: 17px;
`;

const Total = styled.p`
    background-color: var(--green);
    border-radius: 50%;
    color: white;
    font-weight: 800;
    height: 30px;
    padding: 4px;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 10px;
    width: 30px;
`;

const Orders = styled.div`
    margin-top 20px;
    span {
        font-size: 15px;
    }
`;

const DishItem = styled.div`
    display: grid;
    grid-template-columns: minmax(0, max-content) 1fr;
    margin-top: 10px;
    &:first-of-type {
        margin-top: 0;
    }
`;

const Quantity = styled.div`
    align-self: start;
    font-weight: 800;
    position: relative;
    top: -4px;
    letter-spacing: --1px;
    span {
        font-weight: 300;
        padding: 0 2px 0 0;
    }
`;

const DishOrder = styled.div`
    align-self: start;
    color: var(--maroon);
    font-weight: 600;
    line-height: 16px;
    margin-left: 0px;
    padding-left: 10px;
`;

const Paid = styled.div`
    text-align: right;
`;

const Yes = styled.span`
    color: var(--green);
    text-transform: uppercase;
    font-weight: 700;
`;

const No = styled.span`
    color: var(--red);
    text-transform: uppercase;
    font-weight: 700;
`;
