import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TabButtons, SingleTab, Content } from './Tab';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
// local imports
import MyDishes from './MyDishes';
import Orders from './Orders';
import { UPDATE_LIVE } from '../graphql/mutations/updateLive';
import { GET_STATUS } from '../graphql/queries/getStatus';

export default function Tabs() {
    const [active, setActive] = useState(0);
    const [status, setStatus] = useState();

    const handleClick = (e) => {
        const index = parseInt(e.target.id, 0);
        if (index !== active) {
            setActive(index);
        }
    };

    //  EDIT MUTATION
    const [updateLive] = useMutation(UPDATE_LIVE, {
        variables: { id: '60c5779e62aa9459aee75401', status },
        refetchQueries: ['lives'],
    });

    // QUERY IF PAGE IS LIVE STATUS
    const { data } = useQuery(GET_STATUS, {});

    const updateStatus = () => {
        setStatus(!status);
        setTimeout(() => {
            updateLive().then((result) => {
                console.log(result);
            });
        }, 200);
    };

    useEffect(() => {
        if (data) {
            setStatus(data.lives[0].status);
        }
    }, [data]);

    return (
        <Wrapper className="App">
            <TabButtons>
                <SingleTab onClick={handleClick} active={active === 0} id={0}>
                    Orders
                </SingleTab>

                <SingleTab onClick={handleClick} active={active === 1} id={1}>
                    Dishes
                </SingleTab>

                <Checkbox>
                    <label htmlFor="isAvailable">
                        Live
                        <input
                            name="status"
                            type="checkbox"
                            checked={status}
                            onChange={(e) => updateStatus()}
                        />
                    </label>
                </Checkbox>
            </TabButtons>
            <>
                <Content active={active === 0}>
                    <Orders />
                </Content>
                <Content active={active === 1}>
                    <MyDishes />
                </Content>
            </>
        </Wrapper>
    );
}

Tabs.propTypes = {
    user: PropTypes.string,
    access: PropTypes.string,
};

// define your styles
const Wrapper = styled.div`
    margin: 85px auto 0 auto;
    padding: 0px;
    position: relative;
    height: 100vh;
    max-width: 900px;
`;

const Checkbox = styled.div`
    display: inline-block;
    font-weight: 700;
    padding-left: 7px;
    label {
        position: relative;
        top: -7px;
    }
    input {
        color: white;
        width: 35px;
        height: 35px;
        margin-left: 8px;
        position: relative;
        top: 9px;
    }
`;
