import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`


html, body {
    background-color: var(--peach);
    color: #222;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 100%;
}

body > #root {
    height: 100%;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

a:-webkit-any-link {
    color: var(--sydney);
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

:root {
    /**** COLORS ****/
    --salmon: #BF5F4E;
    --maroon: #732F24;
    --peach: #D9B59A;
    --green: #5CBF7B;
    --red: indianred;

    /**** SIZES ****/
    --padding-outer: 30px
`;
