import React, { useState } from 'react';
import styled from 'styled-components';

import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
// local imports
import { ALL_DISHES } from '../graphql/queries/allDishes';
// import { DELETE_WISH } from '../graphql/mutations/deleteWish';
import { UPDATE_DISH } from '../graphql/mutations/updateDish';
// import CreateWish from './CreateWish';

export default function MyWishes({ user }) {
    const [id, setId] = useState('');
    const [dishname, setDishname] = useState('');
    const [description, setDescription] = useState('');
    const [photoUrl, setPhotoUrl] = useState('');
    const [isAvailable, setIsAvailable] = useState(false);
    const [price, setPrice] = useState(0);
    const [capacity, setCapacity] = useState(0);
    const [weeklyTotal, setWeeklyTotal] = useState(0);

    const { loading, data, error, refetch } = useQuery(ALL_DISHES, {
        // variables: { creator: user },
        // refetchQueries: ['GetHomeCategories'],
    });

    // useEffect(() => {
    //     setId(id);
    //     setDishname(dishname);
    //     setPrice(price);
    //     setPhotoUrl(photoUrl);
    //     setDescription(description);
    //     setIsAvailable(isAvailable);
    // }, [description, dishname, id, isAvailable, photoUrl, price]);

    // DELETE MUTATION
    // const [deleteWish] = useMutation(DELETE_WISH, {
    //     variables: { id },
    //     refetchQueries: ['myWishes'],
    // });

    //  EDIT MUTATION
    const [updateDish] = useMutation(UPDATE_DISH, {
        variables: {
            id,
            dishname,
            description,
            photoUrl,
            isAvailable,
            price,
            capacity,
            weeklyTotal,
        },
        refetchQueries: ['dishes'],
    });

    // HANDLING EDIT MODAL

    // const handleEdit = (id, hasPaid) => {
    //     console.log('====================================');
    //     console.log(id, hasPaid);
    //     console.log('====================================');
    // };

    const handleEdit = (
        id,
        dishname,
        description,
        photoUrl,
        price,
        isAvailable,
        capacity,
        weeklyTotal
    ) => {
        setId(id);
        setDishname(dishname);
        setDescription(description);
        setPhotoUrl(photoUrl);
        setPrice(price);
        setIsAvailable(isAvailable);
        setCapacity(capacity);
        setWeeklyTotal(weeklyTotal);

        const editModal = document.getElementById('editModal');
        // Get the <span> element that closes the modal
        const editSpan = document.getElementById('editModalClose');
        const editCancel = document.getElementById('editModalCancel');

        editSpan.onclick = function () {
            editModal.style.display = 'none';
        };

        editCancel.onclick = function () {
            editModal.style.display = 'none';
        };

        setTimeout(function () {
            editModal.style.display = 'block';
        }, 200);
    };

    // ACTUAL EDIT id,

    const actualEdit = () => {
        const editModal = document.getElementById('editModal');
        editModal.style.display = 'none';
        setTimeout(function () {
            updateDish().then((result) => {
                console.log(result);
                const editModal = document.getElementById('editModal');
                editModal.style.display = 'none';
                refetch();
            });
        }, 200);
    };

    if (loading) return <h2>Loading ....</h2>;
    if (error) return `Error! ${error.message}`;

    return (
        <Wrapper>
            <Intro>Click on any item to make changes.</Intro>
            {data.dishes.map((item) => (
                <Dish key={item.id}>
                    <DishContent
                        onClick={() =>
                            handleEdit(
                                item.id,
                                item.dishname,
                                item.description,
                                item.photoUrl,
                                item.price,
                                item.isAvailable,
                                item.capacity,
                                item.weeklyTotal
                            )
                        }
                    >
                        <ImageWrapper>
                            <Image src={item.photoUrl} alt={item.dishname} />
                        </ImageWrapper>
                        <Dishname>{item.dishname}</Dishname>
                        <Description>
                            <strong>Description: </strong>
                            {item.description}
                        </Description>

                        <Available>
                            <strong>Available?: </strong>
                            {item.isAvailable === true ? <Yes>yes</Yes> : <No>no</No>}
                        </Available>
                        <Price>
                            <strong>Price: </strong>${parseFloat(item.price).toFixed(2)}
                            &nbsp;&nbsp;&nbsp;<strong>Capacity: </strong>
                            {item.capacity}
                            &nbsp;&nbsp;&nbsp;<strong>WeeklyTotal: </strong>
                            {item.weeklyTotal}
                        </Price>
                    </DishContent>
                    {/*

                    <DeleteModal id="deleteModal">
                        <DeleteModalContent id="modal-content">
                            <DeleteClose id="modalClose">&times;</DeleteClose>
                            <p>Are you sure you want to delete '{name}'?</p>
                            <DeleteButton onClick={() => actualDelete(item._id)}>
                                Yes, delete it
                            </DeleteButton>{' '}
                            <CancelButton id="modalCancel">cancel</CancelButton>
                        </DeleteModalContent>
                    </DeleteModal>*/}

                    <EditModal id="editModal">
                        <EditModalContent id="edit-modal-content">
                            <EditClose id="editModalClose">&times;</EditClose>
                            <EditWrapper id="editmenu">
                                <EditHeadline>Edit Dish</EditHeadline>
                                <div>
                                    <label htmlFor="dishname">
                                        Dish name
                                        <input
                                            name="dishname"
                                            value={dishname}
                                            placeholder="Enter dish name"
                                            onChange={(e) => setDishname(e.target.value)}
                                        />
                                    </label>
                                </div>
                                <FormDescription>
                                    <label htmlFor="description">
                                        Description
                                        <textarea
                                            name="description"
                                            rows="4"
                                            value={description}
                                            placeholder="Enter description"
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </label>
                                </FormDescription>
                                <div>
                                    <label htmlFor="photoUrl">
                                        Photo URL
                                        <input
                                            name="photoUrl"
                                            value={photoUrl}
                                            placeholder="Enter photo URL"
                                            onChange={(e) => setPhotoUrl(e.target.value)}
                                        />
                                    </label>
                                </div>

                                <div>
                                    <label htmlFor="price">
                                        Price
                                        <input
                                            type="number"
                                            name="price"
                                            value={price}
                                            placeholder="Enter price"
                                            onChange={(e) => setPrice(parseFloat(e.target.value))}
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="capacity">
                                        Capacity
                                        <input
                                            type="number"
                                            name="capacity"
                                            value={capacity}
                                            placeholder="Enter capacity"
                                            onChange={(e) =>
                                                setCapacity(parseFloat(e.target.value))
                                            }
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="weeklyTotal">
                                        Weekly Total
                                        <input
                                            type="number"
                                            name="weeklyTotal"
                                            value={weeklyTotal}
                                            placeholder="Enter weekly total"
                                            onChange={(e) =>
                                                setWeeklyTotal(parseFloat(e.target.value))
                                            }
                                        />
                                    </label>
                                </div>
                                <Checkbox>
                                    <label htmlFor="isAvailable">
                                        Is Available
                                        <input
                                            name="isAvailable"
                                            type="checkbox"
                                            checked={isAvailable}
                                            onChange={(e) => setIsAvailable(!isAvailable)}
                                        />
                                    </label>
                                </Checkbox>
                            </EditWrapper>
                            <EditButton onClick={() => actualEdit()}>Save</EditButton>
                            <EditCancelButton id="editModalCancel">cancel</EditCancelButton>
                        </EditModalContent>
                    </EditModal>
                </Dish>
            ))}
        </Wrapper>
    );
}

MyWishes.propTypes = {
    user: PropTypes.string,
};

// define your styles
const Wrapper = styled.div`
    background-color: var(--salmon);
    overflow: auto;
    padding: 5px 15px 15px 15px;
    position: relative;
`;

const Intro = styled.p`
    color: black;
    font-size: 14px;
    font-style: italic;
    padding: 10px;
`;

const DishContent = styled.div`
    display: block;
`;

const Dish = styled.div`
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    border-top: 1px dotted gray;
    font-size: 15px;
    margin: 15px 0 0 0;
    min-height: 130px;
    padding: 10px;
    &:first-of-type {
        border-top: none;
    }
`;

const Dishname = styled.h3`
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -1px;
    margin-bottom: 10px;
`;
const Description = styled.p`
    margin-bottom: 10px;
    strong {
        color: var(--maroon);
    }
`;

const ImageWrapper = styled.div`
    float: right;
    margin-bottom: 15px;
    margin-left: 15px;
    max-width: 30%;
    position: relative;
`;

const Image = styled.img`
    height: 108px;
    object-fit: cover;
    width: 108px;
`;

const Available = styled.p`
    color: var(--maroon);
    margin-bottom: 10px;
`;
const Price = styled.p`
    strong {
        color: var(--maroon);
    }
`;

const FormDescription = styled.div`
    textarea {
        border: 1px solid black;
        display: block;
        font-size: 17px;
        font-weight: 500;
        padding: 10px;
        width: 100%;
    }
`;

const Yes = styled.span`
    background-color: var(--green);
    border-radius: 20px;
    color: white;
    padding: 3px 10px;
`;

const No = styled.span`
    background-color: var(--red);
    border-radius: 20px;
    color: white;
    padding: 3px 10px;
`;

// delete modal

// const DeleteModal = styled.div`
//     background-color: rgb(0, 0, 0); /* Fallback color */
//     background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
//     display: none; /* Hidden by default */
//     height: 100%; /* Full height */
//     left: 0;
//     overflow: auto; /* Enable scroll if needed */
//     padding-top: 100px; /* Location of the box */
//     position: fixed; /* Stay in place */
//     top: 0;
//     width: 100%; /* Full width */
//     z-index: 1; /* Sit on top */
// `;

// const DeleteModalContent = styled.div`
//     background-color: #fefefe;
//     border: 1px solid #888;
//     font-weight: 600;
//     margin: auto;
//     max-width: 500px;
//     padding: 20px;
//     width: 80%;

//     p {
//         font-size: 22px;
//         margin-bottom: 20px;
//         padding-right: 40px;
//     }
// `;

// const DeleteClose = styled.span`
//     color: #aaaaaa;
//     float: right;
//     font-size: 34px;
//     font-weight: bold;
//     position: relative;
//     top: -11px;
//     &:hover {
//         color: #000;
//         text-decoration: none;
//         cursor: pointer;
//     }
//     &:focus {
//         color: #000;
//         text-decoration: none;
//         cursor: pointer;
//     }
// `;

// const DeleteButton = styled.button`
//     font-size: 15px;
//     margin-right: 25px;
//     padding: 10px 20px;
// `;

// const CancelButton = styled.a`
//     border: none;
//     font-size: 14px;
//     font-weight: 400;
//     text-decoration: underline;
//     &:hover {
//         cursor: pointer;
//         text-decoration: none;
//     }
// `;

// edit modal
const EditModal = styled.div`
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    display: none; /* Hidden by default */
    height: 100%; /* Full height */
    left: 0;
    overflow: auto; /* Enable scroll if needed */
    padding-top: 70px; /* Location of the box */
    position: fixed; /* Stay in place */
    top: 0;
    width: 100%; /* Full width */
    z-index: 1; /* Sit on top */
`;

const EditModalContent = styled.div`
    background-color: var(--peach);
    border: none;
    font-weight: 600;
    margin: auto;
    padding: 20px;
    width: 80%;
    border-radius: 8px;

    p {
        margin-bottom: 20px;
        padding-right: 40px;
    }
`;

const EditClose = styled.span`
    color: white;
    float: right;
    font-size: 34px;
    font-weight: bold;
    position: relative;
    top: -11px;
    z-index: 2;
    &:hover {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
    &:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
`;

const EditButton = styled.button`
    font-size: 15px;
    font-weight: 600;
    margin: 20px 25px 0 0;
    padding: 15px 40px;
    background-color: var(--salmon);
    color: white;
    border: none;
`;

const EditCancelButton = styled.a`
    border: none;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
`;

const EditWrapper = styled.div`
    position: relative;
    div {
        margin-top: 20px;
        &:first-of-type {
            margin-top: 0;
        }
    }
    label {
        color: var(--maroon);
        display: block;
        margin-bottom: 3px;
    }
    input {
        background-color: white;
        font-size: 17px;
        font-weight: 500;
        padding: 10px;
        width: 100%;
    }
`;

const Checkbox = styled.div`
    input {
        color: white;
        display: block;
        width: 35px;
        height: 35px;
        margin-top: 8px;
    }
`;

const EditHeadline = styled.h1`
    margin-bottom: 20px;
    padding-right: 40px;
    color: white;
`;
