import gql from 'graphql-tag';

export const UPDATE_DISH = gql`
    mutation UpdateDish(
        $id: ID!
        $dishname: String
        $photoUrl: String
        $description: String
        $isAvailable: Boolean
        $price: Float
        $capacity: Int
        $weeklyTotal: Int
    ) {
        updateDish(
            id: $id
            data: {
                dishname: $dishname
                photoUrl: $photoUrl
                description: $description
                isAvailable: $isAvailable
                price: $price
                capacity: $capacity
                weeklyTotal: $weeklyTotal
            }
        ) {
            id
            dishname
            description
            photoUrl
            isAvailable
            price
            capacity
            weeklyTotal
        }
    }
`;
