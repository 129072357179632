import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export default function Login({ handleLogin, setPassword }) {
    return (
        <FormWrapper>
            <form onSubmit={handleLogin}>
                <div>
                    <input
                        name="password"
                        type="password"
                        placeholder="Enter password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div>
                    <Input type="submit" value="Submit" />
                </div>
                {/* ERROR IF INVALID LOGIN */}
                <div id="error" />
            </form>
        </FormWrapper>
    );
}

Login.propTypes = {
    handleLogin: PropTypes.any,
    setPassword: PropTypes.any,
};

// styles

const FormWrapper = styled.div`
    margin-top: 25px;
    margin: 0 auto;
    max-width: 300px;
    padding: 40px;
    width: 80%;

    div {
        padding: 5px;
    }

    input {
        font-size: 16px;
        padding: 10px;
        text-align: center;
        width: 100%;
    }
`;

const Input = styled.input`
    background-color: var(--salmon);
    border-radius: 20px;
    border: 0;
    color: white;
    letter-spacing: 5px;
    outline: none;
    padding: 15px !important;
    text-transform: uppercase;
    width: 100%;
    -webkit-appearance: none;
`;
