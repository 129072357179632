import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export default function Header({ name, handleLogout }) {
    return (
        <>
            <StyledHeader>
                <Title>
                    <AppTitle>Meal Prep Admin</AppTitle>
                    <Welcome>Welcome, {name}!</Welcome>
                </Title>

                {name ? (
                    <UserArea>
                        <Logout onClick={handleLogout}>Logout</Logout>
                    </UserArea>
                ) : (
                    <UserArea></UserArea>
                )}
            </StyledHeader>
        </>
    );
}

Header.propTypes = {
    name: PropTypes.string,
    handleLogout: PropTypes.any,
    user: PropTypes.string,
    menuState: PropTypes.string,
    setMenuState: PropTypes.any,
};

const StyledHeader = styled.div`
    background-color: white;
    border-bottom: 1px solid lightgray;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
    display: flex;
    padding: 20px 20px 15px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
`;

const Title = styled.div`
    flex: 3;
    padding-left: 0px;
    text-align: left;
    text-transform: capitalize;
`;

const AppTitle = styled.h1`
    color: var(--salmon);
    font-size: 25px;
    font-weight: 900;
    letter-spacing: -1px;
    line-height: 25px;
    margin-bottom: 5px;
`;

const UserArea = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 12px;
    position: relative;
    top: 6px;
`;

const Welcome = styled.p`
    color: #ccc;
    font-family: Arial, Helvetica, sans-serif, sans-serif;
    font-size: 14px;
    font-weight: 300;
    padding-left: 5px;
    text-transform: capitalize;
`;

const Logout = styled.button`
    background-color: var(--peach);
    border-radius: 40px;
    border: 1px solid var(--peach);
    color: white;
    font-size: 12px;
    font-weight: 600;
    margin: 0 5px 5px 0;
    outline: none;
    padding: 6px 15px;
    transition: 0.4s all;
    &:hover {
        border: 1px solid #222;
        cursor: pointer;
    }
`;
