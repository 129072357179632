import styled from 'styled-components';
export const TabButtons = styled.div`
    font-family: Open Sans;
    overflow: hidden;
    padding: 20px 20px 0 20px;
`;

export const SingleTab = styled.button`
    background-color: ${(props) => (props.active ? 'var(--salmon)' : 'gray')};
    border: none;
    color: ${(props) => (props.active ? 'white' : 'lightgray')};
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    height: 55px;
    margin-right: 10px;
    outline: none;
    padding-top: 5px;
    position: relative;
    transition: background-color 0.5s ease-in-out;
    width: 35%;
    @media screen and (max-width: 400px) {
        width: 30%;
    }
`;
export const Content = styled.div`
    ${(props) => (props.active ? '' : 'display:none')}
`;
