import gql from 'graphql-tag';

export const UPDATE_LIVE = gql`
    mutation UpdateLive($id: ID!, $status: Boolean) {
        updateLive(id: $id, data: { status: $status }) {
            id
            status
        }
    }
`;
