import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// LOCAL IMPORTS
import Header from './components/Header';
import userList from './userList.js';
import Login from './components/Login';
import Tabs from './components/Tabs';

function App() {
    const initialUser = () => window.localStorage.getItem('user') || '';

    const [user, setUser] = useState(initialUser);
    const [password, setPassword] = useState('');

    useEffect(() => {
        window.localStorage.setItem('user', user);
    }, [user]);

    const handleLogin = (e) => {
        e.preventDefault();

        // SEE IF HANDLED USER & PASSWORD ARE IN FAMILY LIST

        const filteredUsers = userList.filter(function (fam) {
            return fam.password === password;
        });

        // IF FILTEREDUSERS ARRAY EXISTS ("[0]"), SET LOCAL STORAGE

        if (filteredUsers[0]) {
            setUser(filteredUsers[0].username);
        } else {
            document.getElementById('error').innerHTML = 'Invalid Login';
        }
    };

    // LOGOUT: SET ITEMS TO BLANK STRINGS, RELOAD PAGE.

    const handleLogout = (e) => {
        e.preventDefault();
        window.localStorage.setItem('user', '');
        window.localStorage.setItem('access', '');
        setTimeout(function () {
            window.location.reload(true);
        }, 100);
    };

    return (
        <>
            {/* IS USER VALUE IN LOCAL STORAGE? */}
            {user ? (
                // IF SO, SHOW TABBED CONTENT
                <Wrapper>
                    <Header name={user} handleLogout={handleLogout} />
                    <Tabs user={user} />
                </Wrapper>
            ) : (
                // IF NOT, THEY GET A LOGIN FORM
                <LoginWrapper>
                    <Headline>Meal prep time!</Headline>
                    <Login handleLogin={handleLogin} setPassword={setPassword} />
                </LoginWrapper>
            )}
        </>
    );
}

// define your styles
const Wrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
`;

const LoginWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    padding-top: 50%;

    @media only screen and (min-width: 501px) {
        background: url('https://res.cloudinary.com/billpliske/image/upload/v1593906220/emily/dinner-vertical.jpg');
        background-attachment: fixed;
        background-size: cover;
        background-position: center;
    }
    background: url('https://res.cloudinary.com/billpliske/image/upload/v1593906220/emily/dinner-vertical.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
`;

const Headline = styled.h1`
    background-color: black;
    border-radius: 10px;
    color: var(--salmon);
    font-size: 31px;
    font-weight: 300;
    line-height: 28px;
    margin: 6vh auto 0 auto;
    max-width: 80vw;
    padding: 10px;
    text-align: center;
    @media only screen and (min-width: 360px) {
        font-size: 37px;
        line-height: 32px;
        padding: 10px;
    }
    @media only screen and (min-width: 400px) {
        font-size: 42px;
        line-height: 36px;
    }
    @media only screen and (min-width: 700px) {
        font-size: 60px;
        line-height: 55px;
    }
    @media only screen and (min-width: 900px) {
        font-size: 65px;
        line-height: 61px;
    }
    @media only screen and (min-width: 1200px) {
        font-size: 70px;
        line-height: 59px;
    }
`;

export default App;
