import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
// local imports
import App from './App';
import { GlobalStyle } from './DefaultStyles';
import { db } from './Api';

const httpLink = createHttpLink({
    uri: db,
});

const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
});

const ApolloApp = () => (
    <ApolloProvider client={client}>
        <GlobalStyle />
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ApolloProvider>
);

ReactDOM.render(<ApolloApp />, document.getElementById('root'));
