import gql from 'graphql-tag';

export const ALL_DISHES = gql`
    query dishes {
        dishes {
            id
            dishname
            description
            photoUrl
            isAvailable
            price
            capacity
            weeklyTotal
        }
    }
`;
